import React from 'react'
import View from '../components/ProductViewer'
import { graphql } from 'gatsby'

const ViewPage = ({data, pageContext}) => {
  let products = data.allMarkdownRemark?.edges
  return(
    <View data={data} context={pageContext}
    products={products}  />
  )
}

export default ViewPage

export const query = graphql`
query MyImages {
  allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/products/"}}) {
    edges {
      node {
        fileAbsolutePath
        image {
          relativePath
          id
        }
        frontmatter {
          smart_code
          image
          image_dimensions
          model
          short_description
          contents
          product_class
          net_price {
            currency
            value
          }
          product_name
          configurator_id
          external_documents{
            document_title
            document_url
            document_type
          }
          product_name
          product_class
          product_name_override
          configurator_id
          smart_code
          default_image
          default_configuration
          qc_status
          product_category
          uses_light
          website_link
        }
      }
    }
  }
  allFile(filter: {extension: {regex: "/(jpg)|(png)|(jpeg)/"}}) {
    edges {
      node {
        id
        childImageSharp {
          gatsbyImageData(
            width: 1024
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  }
}
`
