import React, { Component } from 'react'
import {
  Button,
  InputLabel,
  FormControl,
  Select,
  MenuItem
} from '@mui/material'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { navigate } from '../../utils/misc'

class ProductsBrowser extends Component {
  constructor(props) {
    super(props)
    let activeCategory = 'Bench'
    if(this.props.product){
      activeCategory = this.props.product.product_category
    }
    let categories = []
    let products = []
    if(this.props.products){
      this.props.products.forEach((item, i) => {
        if(item.node.frontmatter.qc_status === 'QC CHECKED' && item.node.frontmatter.configurator_id){
          products.push(item.node)
          let category = item.node.frontmatter.product_category
          if(!categories.includes(category)){
            categories.push(category)
          }
        }
      })
    }
    categories.sort()
    let filteredProducts = products.filter((item)=>{
      return item.frontmatter.product_category === activeCategory
    })
    let activeProduct = ''
    filteredProducts.sort((a,b)=>{
      if(a.frontmatter.product_name > b.frontmatter.product_name){
        return 1
      }else if(a.frontmatter.product_name < b.frontmatter.product_name){
        return -1
      }else{
        return 0
      }
    })
    if(filteredProducts.length){
      if(filteredProducts[0]?.frontmatter?.configurator_id){
        activeProduct = filteredProducts[0].frontmatter.configurator_id
      }
    }
    this.state = {
      categories: categories,
      activeCategory: activeCategory,
      products: products,
      filteredProducts: filteredProducts,
      activeProduct: activeProduct
    }
  }

  componentDidMount(){

  }

  handleChange = (e) => {
    this.activateCategory(e.target.value)
  }

  activateCategory = (category) => {
    let filteredProducts = this.state.products.filter((item)=>{
      return item.frontmatter.product_category === category
    })
    filteredProducts.sort((a,b)=>{
      if(a.frontmatter.product_name > b.frontmatter.product_name){
        return 1
      }else if(a.frontmatter.product_name < b.frontmatter.product_name){
        return -1
      }else{
        return 0
      }
    })
    this.setState({
      activeCategory: category,
      filteredProducts: filteredProducts,
      activeProduct: filteredProducts[0].frontmatter.configurator_id
    })
  }

  activateProduct = (configurator_id) => {
    this.setState({
      activeProduct: configurator_id
    })
    let activeProduct = this.state.filteredProducts.find(object=>{
      return object.frontmatter.configurator_id === this.state.activeProduct
    })
    if(this.props.onSelect){
      this.props.onSelect(activeProduct)
    }
  }

  getCategoryPlural = (category) => {
    let plurals = [
      {name: 'Bench', plural: 'Benches'},
      {name: 'Bike Rack', plural: 'Bike Racks'},
      {name: 'Bench', plural: 'Benches'},
      {name: 'Lighting', plural: 'Lighting + Security'},
      {name: 'Litter & Recycling', plural: 'Litter Receptacles'},
      {name: 'Planter', plural: 'Planters'},
      {name: 'Table & Chair', plural: 'Chairs + Tables'}
    ]
    let pluralFound = plurals.find((object)=>{
      return object.name.toLowerCase() === category.toLowerCase()
    })
    if(pluralFound){
      return pluralFound.plural
    }else{
      return category
    }
  }

  render() {
    let uniqueCategories = []
    this.props.productCategories.forEach((category, i) => {
      if(category.edges.length){
        let categoryName = category.edges[0].node.frontmatter.product_category
        if(!uniqueCategories.includes(categoryName)){
          uniqueCategories.push(categoryName)
        }
      }
    })
    let categoryPlurals = uniqueCategories.map((item, i)=>{
      return {
        category: item,
        plural: this.getCategoryPlural(item)
      }
    })
    categoryPlurals.sort((a,b)=>{
      if(a.plural > b.plural){
        return 1
      }
      if(a.plural < b.plural){
        return -1
      }
      return 0
    })
    return(
      <div className='browser-wrapper'>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Select Category</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={this.state.activeCategory}
            onChange={this.handleChange}
          >
            {categoryPlurals.map((item, i)=>{
              return (
                <MenuItem value={item.category} key={i}>{item.plural}</MenuItem>
              )
            })}
          </Select>
        </FormControl>
        <div className="product-browser">
        {this.state.filteredProducts.map((item, i)=>{
          let defaultImage = <img alt={item.frontmatter.product_name} src={item.frontmatter.default_image}/>
          let productImage = this.props.images.find(function(image){
            return image.node.id === item.image?.id
          })
          if(productImage){
            const image = getImage(productImage.node)
            defaultImage = <GatsbyImage image={image} alt={item.frontmatter.product_name} />
          }else{
            if(item.image?.publicURL){
              defaultImage = <img src={item.image.publicURL} alt={item.frontmatter.product_name} />
            }
          }
          let productLink = '/view/' + item.frontmatter.configurator_id
          return (<Button key={i}
            title={item.frontmatter.product_name}
            onClick={()=>{
              navigate(productLink)
              }}>
            {defaultImage}
            <div>{item.frontmatter.product_name}</div>
            </Button>)
        })}
        </div>
      </div>
    )
  }
}

export default ProductsBrowser
