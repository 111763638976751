import React, { Component } from 'react'
import { Link } from 'gatsby'
import {
  Button,
  IconButton,
  Drawer
} from '@mui/material'
import AuthPanels from './auth-panels'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'

class AuthDrawer extends Component {
  constructor(props) {
    super(props)
    this.state = {

    }
  }

  componentDidMount(){

  }

  render() {

    return(
      <div className='auth-drawer' style={{
        display: 'flex',
        flexGrow: '1',
        flexDirection: 'column',
        width: '100%',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        border: 'solid 1px'
      }}>
        <div>
          <AuthPanels
          handleSetLoading={this.props.handleSetLoading}
          defaultView={this.props.defaultView}
          authDrawer={true}
          returnFromLogin={this.props.returnFromLogin}
          setSuccessMessage={this.props.setSuccessMessage}
          setErrorMessage={this.props.setErrorMessage}
          />
        </div>
        <IconButton onClick={this.props.closeDrawer}
        style={{
          position: 'absolute',
          top: '1rem',
          right: '1rem',
          zIndex: 10
        }}>
        <ChevronLeftIcon />
        </IconButton>
      </div>
    )
  }
}

export default AuthDrawer
